import React from 'react';
import { SummaryBlockType } from '../../../types/summary.types';
import Flex from '../../common/layout/Flex';
import * as styles from './Blocks.module.scss';
import BlockItem from './BlockItem';
import ContentWrap from '../../ContentWrap/ContentWrap';

type BlocksProps = {
  items: SummaryBlockType[];
};
const Blocks = ({ items }: BlocksProps) => (
  <ContentWrap>
    <div className={styles.wrapper}>
      <Flex flexWrap="wrap" justifyContent="center">
        {items.map((item) => (
          <BlockItem
            title={item.title}
            text={item.text}
            key={item.url}
            url={item.url}
            images={item.images}
          />
        ))}
      </Flex>
    </div>
  </ContentWrap>
);

export default Blocks;
