// extracted by mini-css-extract-plugin
export var antBtnLg = "BlockItem-module--ant-btn-lg--mFXSt";
export var antBtnPrimary = "BlockItem-module--ant-btn-primary--z2VBU";
export var antFormItem = "BlockItem-module--ant-form-item--tHt02";
export var antFormItemLabel = "BlockItem-module--ant-form-item-label--Dkz4q";
export var antInput = "BlockItem-module--ant-input--n6aBQ";
export var antMessageNotice = "BlockItem-module--ant-message-notice--7Kkbm";
export var antMessageNoticeContent = "BlockItem-module--ant-message-notice-content--ZrJch";
export var button = "BlockItem-module--button--iYHgx";
export var errorMessage = "BlockItem-module--errorMessage--OxfBz";
export var image = "BlockItem-module--image--v7-Yq";
export var text = "BlockItem-module--text--Zz70n";
export var title = "BlockItem-module--title--CkrxX";
export var wrapper = "BlockItem-module--wrapper--aGISP";
export var wrapperInner = "BlockItem-module--wrapperInner--E0eHG";