// extracted by mini-css-extract-plugin
export var antBtnLg = "Head-module--ant-btn-lg--7a5R+";
export var antBtnPrimary = "Head-module--ant-btn-primary--4vZ+z";
export var antFormItem = "Head-module--ant-form-item--WuHEO";
export var antFormItemLabel = "Head-module--ant-form-item-label--Bjqt5";
export var antInput = "Head-module--ant-input--5xWRk";
export var antMessageNotice = "Head-module--ant-message-notice--OVINw";
export var antMessageNoticeContent = "Head-module--ant-message-notice-content--rj9zM";
export var errorMessage = "Head-module--errorMessage--B88Cq";
export var image = "Head-module--image--gab7Q";
export var left = "Head-module--left--VHVrE";
export var light = "Head-module--light--rQwWJ";
export var right = "Head-module--right--rODBu";
export var text = "Head-module--text--biHSY";
export var title = "Head-module--title--PLu9M";
export var wrapper = "Head-module--wrapper--L6kvo";