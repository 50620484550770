import React from 'react';
import * as styles from './Head.module.scss';
import ContentWrap from '../../ContentWrap/ContentWrap';
import { SummaryHeadType } from '../../../types/summary.types';
import classNames from 'classnames';

const Head = ({ title, text, light }: SummaryHeadType) => (
  <div className={classNames(styles.wrapper, light && styles.light)}>
    <ContentWrap>
      <h1 className={styles.title}>{title}</h1>
      <div className={styles.text}>{text}</div>
    </ContentWrap>
    <div className={`${styles.image} ${styles.left}`} />
    <div className={`${styles.image} ${styles.right}`} />
  </div>
);

export default Head;
