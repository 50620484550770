import React from 'react';
import { graphql } from 'gatsby';
import { Trans } from 'gatsby-plugin-react-i18next';

import ImageContainer from '../components/ImageContainer/ImageContainer';
import MainWrapper from '../layouts';
import SummaryLayout from '../components/SummaryLayout/SummaryLayout';
import { LanguageMap } from '../const/language.const';
import block1RuSrc from '../assets/images/summary/functions/block_1_ru.png';
import block1RuSrc2x from '../assets/images/summary/functions/block_1_ru_2x.png';
import block1Src from '../assets/images/summary/functions/block_1.png';
import block1Src2x from '../assets/images/summary/functions/block_1_2x.png';
import block2Src from '../assets/images/summary/functions/block_2.png';
import block2Src2x from '../assets/images/summary/functions/block_2_2x.png';
import block3Src from '../assets/images/summary/functions/block_3.png';
import block3Src2x from '../assets/images/summary/functions/block_3_2x.png';
import block4Src from '../assets/images/summary/functions/block_4.png';
import block4Src2x from '../assets/images/summary/functions/block_4_2x.png';
import block5Src from '../assets/images/summary/functions/block_5.png';
import block5Src2x from '../assets/images/summary/functions/block_5_2x.png';
import block6Src from '../assets/images/summary/functions/block_6.png';
import block6Src2x from '../assets/images/summary/functions/block_6_2x.png';
import block7Src from '../assets/images/summary/functions/block_7.png';
import block7Src2x from '../assets/images/summary/functions/block_7_2x.png';
import {
  API_AUTOMATION_ROUTE,
  COOKIES_ROUTE,
  FINGERPRINT_MANAGEMENT_ROUTE,
  MULTIACCOUNTING_ROUTE,
  PROFILES_ROUTE,
  PROXY_ROUTE,
  TEAMWORK_ROUTE,
} from '../const/routes.const';

const SolutionsPage = (): JSX.Element => {
  const head = {
    title: <Trans>Functions</Trans>,
    text: (
      <Trans>
        Оцените широкий функционал Octo Browser, улучшающий комфорт и эффективность как самостоятельной, так и командной работы.
      </Trans>
    ),
  };

  const blocks = [
    {
      title: <Trans>Мульти-аккаунтинг</Trans>,
      text: (
        <Trans>
          Запускайте одновременно множество профилей с детально настроенными
          параметрами. Клонируйте, экспортируйте, группируйте по тегам или
          осуществляйте трансфер профилей внутри команды.
        </Trans>
      ),
      url: MULTIACCOUNTING_ROUTE,
      images: [
        {
          image: (
            <ImageContainer src={block1RuSrc} srcSet={{ _2x: block1RuSrc2x }} />
          ),
          locales: {
            include: [LanguageMap.ru.key],
          },
        },
        {
          image: (
            <ImageContainer src={block1Src} srcSet={{ _2x: block1Src2x }} />
          ),
          locales: {
            exclude: [LanguageMap.ru.key],
          },
        },
      ],
    },
    {
      title: <Trans>Fingerprint management</Trans>,
      text: (
        <Trans>
          Управляйте цифровым отпечатком профиля, используя передовые и надежные
          алгоритмы подмен, выполненные в коде браузера на ядре Chromium.
        </Trans>
      ),
      url: FINGERPRINT_MANAGEMENT_ROUTE,
      images: [
        {
          image: (
            <ImageContainer src={block2Src} srcSet={{ _2x: block2Src2x }} />
          ),
        },
      ],
    },
    {
      title: <Trans>Teamwork</Trans>,
      text: (
        <Trans>
          Настраивайте доступы, отслеживайте историю действий, сохраняя
          конфиденциальность рабочих процессов внутри команды.
        </Trans>
      ),
      url: TEAMWORK_ROUTE,
      images: [
        {
          image: (
            <ImageContainer src={block3Src} srcSet={{ _2x: block3Src2x }} />
          ),
        },
      ],
    },
    {
      title: <Trans>API automation</Trans>,
      text: (
        <Trans>
          Автоматизируйте рутинные процессы при работе с профилями на удобном
          для вас языке программирования.
        </Trans>
      ),
      url: API_AUTOMATION_ROUTE,
      images: [
        {
          image: (
            <ImageContainer src={block4Src} srcSet={{ _2x: block4Src2x }} />
          ),
        },
      ],
    },
    {
      title: <Trans>Profiles</Trans>,
      text: (
        <Trans>
          Детальная настройка параметров профиля и множество встроенных
          инструментов для комфортной и эффективной работы.
        </Trans>
      ),
      url: PROFILES_ROUTE,
      images: [
        {
          image: (
            <ImageContainer src={block5Src} srcSet={{ _2x: block5Src2x }} />
          ),
        },
      ],
    },
    {
      title: <Trans>Proxy</Trans>,
      text: (
        <Trans>
          Поддержка популярных типов прокси, а также удобный импорт, экспорт,
          загрузка и трансфер между командами.
        </Trans>
      ),
      url: PROXY_ROUTE,
      images: [
        {
          image: (
            <ImageContainer src={block6Src} srcSet={{ _2x: block6Src2x }} />
          ),
        },
      ],
    },
    {
      title: <Trans>Cookies</Trans>,
      text: (
        <Trans>
          Множество возможностей и функций для работы со всеми популярными
          типами cookies, включая импорт, экспорт и встроенный cookie-робот.
        </Trans>
      ),
      url: COOKIES_ROUTE,
      images: [
        {
          image: (
            <ImageContainer src={block7Src} srcSet={{ _2x: block7Src2x }} />
          ),
        },
      ],
    },
  ];
  return (
    <MainWrapper>
      <SummaryLayout head={head} blocks={blocks} />
    </MainWrapper>
  );
};

export default SolutionsPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["functionsPage", "translation"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
