import React from 'react';
import { SummaryBlockType, SummaryHeadType } from '../../types/summary.types';
import Head from './Head/Head';
import Blocks from './Bclocks/Blocks';
import DownBlock from '../DownBlock/DownBlock';

type SummaryLayoutProps = {
  head: SummaryHeadType;
  blocks: SummaryBlockType[];
};
const SummaryLayout = ({
  head,
  blocks,
}: SummaryLayoutProps): JSX.Element => (
  <>
    <Head {...head} />
    <Blocks items={blocks} />
    <DownBlock />
  </>
);

export default SummaryLayout;
