import React from 'react';
import { Link, Trans } from 'gatsby-plugin-react-i18next';
import { SummaryBlockType } from '../../../types/summary.types';
import Button from '../../Button/Button';
import Flex from '../../common/layout/Flex';
import useImagesByLocale from '../../../hooks/useImagesByLocale.hook';
import * as styles from './BlockItem.module.scss';

export type BlockItemProps = SummaryBlockType;
const BlockItem = ({ title, text, url, images }: BlockItemProps) => {
  const localeImages = useImagesByLocale(images);

  return (
    <Flex className={styles.wrapper}>
      <Link className={styles.wrapperInner} to={url}>
        <div>
          <h2 className={styles.title}>{title}</h2>
          <div className={styles.text}>{text}</div>
          <div className={styles.button}>
            <Button label={<Trans>Learn more</Trans>} />
          </div>
        </div>
        <div className={styles.image}>
          {localeImages.map((image, idx) => (
            <div className={styles.image} key={idx}>
              {image.image}
            </div>
          ))}
        </div>
      </Link>
    </Flex>
  );
};

export default BlockItem;
